#form-page {
  .form-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
    justify-content: space-between;

    img {
      width: 100%;
      min-height: 153px;
      object-fit: cover;
    }

    .Form-item {
      text-align: center;
    }
  }
}
ion-item-group.formType-group.md.item-group-md.item.hydrated {
  width: 50%;
}

ion-content {
  height: 70%;
  text-align: center;
}

.no-content-text {
  padding-top: 40%;
  font-size: larger;
}

.warning {
  color: red;
}

#form-question-page {
  ion-content.photo.md.hydrated {
    min-height: 400px;
  }

  ion-img.md.hydrated {
    width: 200px;
  }

  .photo {
    display: flex;
    overflow: wrap;
  }
  .photo-gallery {
    display: flex;
    overflow: wrap;
  }
}
