#subject-list {

  .topic-list, .subject-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
    justify-content: space-between;

    img {
      width: 100%;
      min-height: 153px;
      object-fit: cover;
    }
  }

  .subject-card {
    display: flex;
    flex-direction: column;
    width: 100%
  }

  .topic-card {
    box-shadow: none;
    margin-bottom: 5%;
  }

  /* Due to the fact the cards are inside of columns the margins don't overlap
   * properly so we want to remove the extra margin between cards
   */
  ion-col:not(:last-of-type) .subject-card {
    margin-bottom: 0;
  }

  .subject-card .subject-item {
    --min-height: 85px;
  }

  .subject-card .subject-item h2 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.02em;
    white-space: normal;
    text-align: center;
  }

  .subject-card .subject-item p {
    font-size: 13px;
    letter-spacing: 0.02em;
  }

  .subject-card ion-card-header {
    padding: 0;
  }

  .subject-card ion-card-content, .topic-card ion-card-content {
    flex: 1 1 auto;

    padding: 0;
  }

  .ios ion-list {
    margin-bottom: 10px;
  }

  .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);

    padding: 0;
  }
}


.topic-group {
  width: 45%;
  margin: auto;
}

.topic-item {
  height: auto;
  padding-bottom: 10%;
  text-align: center;
  --border-style: none;
  font-size: large;
}
