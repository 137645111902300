#home-page, #water-systems-page{
  font-weight: 400;
  .page-items {
    display: flex;
    padding: 3%;
    margin: 3%;
    flex-wrap: wrap;
    justify-content: space-between;
    .page-item {
      width: 45%;
      height: auto;
      padding-bottom: 10%;
      text-align: center;
      --border-style: none;
      font-size: large;
      .photo {
        width: 100%;
      }
    }
  }
  .subsection {
    font-size: medium;
  }
}