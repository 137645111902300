#lesson-detail {
  .lesson-slide {
    padding-bottom: 140px;
  }
  .lesson-text {
    text-align: justify;
  }
  .lesson-logo {
    max-height: 400px;
    max-width: 400px;
    width: 100%;
    margin: auto;
  }
  .lesson-card {
    width: 100%
  }
  .lesson-photo-slider {
    max-width: 100%;
    height: initial;
    overflow: auto;
  }
  .lesson-photo-slide {
    max-width: 100%;
    height: 100%;
    overflow: auto;
  }
  .image-zoom-container {
    padding-top: 100px;
    padding-bottom: 300px;
  }
  .image-zoom-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
}
